'use strict';

// var existingOnload = window.onload || function() {};

var PlaceHolder = function() {
    var placeholder = document.querySelectorAll('.img-placeholder'),
        small = document.querySelectorAll('.img-placeholder .img-small');

    // 1: load small image and show it
    small.forEach(el => {
        var img = new Image();

        img.src = el.src;
        img.onload = function() {
            el.classList.add('loaded');
        };
    });

    // 2: load large image
    placeholder.forEach(element => {
        var imgLarge = new Image();

        imgLarge.src = element.dataset.large;
        imgLarge.alt = element.dataset.alt;
        var classes = element.dataset.class.split(/\s/i);
        classes.forEach(cls => {
            if (cls.trim().length) {
                imgLarge.classList.add(cls.trim());
            }
        });
        imgLarge.onload = function() {
            imgLarge.classList.add('loaded');
        };
        element.appendChild(imgLarge);
    });
};

// window.onload = function(self, ev) {
//     existingOnload(self, ev);

//     PlaceHolder();
// };

window.addEventListener('DOMContentLoaded', PlaceHolder);

export default PlaceHolder;
