'use strict';

(function(window) {
    class MarsoMaps {
        constructor(mapDomId = 'map') {

            let _rootPath = '';

            var _self = this;

            if (!$(`#${mapDomId}`).length) {
                return;
            }

            // get country
            this.country = document.documentElement.getAttribute('lang').split('-')[0];
            const _scriptFilenameRegexp = /app[\.\w]*\.js/;
            const scripts = document.getElementsByTagName('script');
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src && _scriptFilenameRegexp.test(scripts[i].src)) {
                    _rootPath = scripts[i].src.substring(0, scripts[i].src.lastIndexOf('/') + 1);
                    break;
                }
            }

            this.locationIcon = new google.maps.Marker({
                // @ts-ignore
                size: new google.maps.Size(45.0, 55.0),
                anchor: new google.maps.Point(27.5, 35.0),
                url: _rootPath + '../images/location.png'
            });

            this.marsoIcon = new google.maps.Marker({
                // @ts-ignore
                size: new google.maps.Size(45.0, 55.0),
                anchor: new google.maps.Point(27.5, 35.0),
                url: _rootPath + '../assets/img/wrench-location.png'
            });

            // Create a map object and specify the DOM element for display.
            this._map = new google.maps.Map(document.getElementById(mapDomId), {
                center: { lat: 47.1556941, lng: 19.4716342 },
                scrollwheel: false,
                zoom: 8,
                mapTypeControl: false,
                rotateControl: false,
                fullscreenControl: false
            });

            // Associate the styled map with the MapTypeId and set it to display.
            this._map.mapTypes.set('styled_map', new google.maps.StyledMapType(window.mapstyle));
            this._map.setMapTypeId('styled_map');
            this._markers = [];
            this._currentPosition = {};

            // fit bounds event listener for correct zoom, fitBounds is async, so listen to it
            google.maps.event.addListenerOnce(_self._map, 'bounds_changed', function (event) {
                // remove one zoom level to ensure no marker is on the edge.
                _self._map.setZoom(_self._map.getZoom() - 1);

                if (_self._map.getZoom() > 15) {
                    _self._map.setZoom(15);
                }
            });
        }

        fit(markers = this._markers) {
            if (typeof this._map === 'undefined') {
                return false;
            }

            const bounds = new google.maps.LatLngBounds();

            for (let i = 0; i < markers.length; i++) {
                bounds.extend(markers[i].getPosition());
            }

            // center the map to the geometric center of all markers
            this._map.setCenter(bounds.getCenter());

            // zoom to markers
            this._map.fitBounds(bounds);
        }

        geolocate(callback) {
            var _self = this;
            // Try HTML5 geolocation.
            google.maps.event.addListenerOnce(this._map, 'idle', function() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        _self._currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        // @ts-ignore
                        new google.maps.Marker({
                            position: _self._currentPosition,
                            map: _self._map,
                            title: 'A te pozíciód',
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: '#4285F4',
                                fillOpacity: 0.8,
                                scale: 8,
                                strokeColor: '#999',
                                strokeWeight: 3,
                                strokeOpacity: 0.6
                            }
                        });

                        if (typeof callback != 'undefined') {
                            callback(_self._currentPosition);
                        }
                    });
                }
            });
        }

        setMarkers(markers) {
            this.hideMarkers();
            this._markers = markers;
            this.showMarkers();
        }

        getMap() {
            return this._map;
        }

        hideMarkers() {
            for (let i = 0; i < this._markers.length; i++) {
                this._markers[i].setMap(null);
            }
        }

        showMarkers() {
            for (let i = 0; i < this._markers.length; i++) {
                this._markers[i].setMap(this._map);
            }
        }

        getMarkerTemplate(location, title, isOwnService) {
            const marker = new google.maps.Marker({
                    position: { lat: location.lat, lng: location.lng },
                    title: title,
                    icon: isOwnService ? this.marsoIcon : this.locationIcon
                });

            return marker;
        }
    }

    window.MarsoMaps = MarsoMaps;
})(window);
