(function ($) {
    $('#contact_position').on('change', function(){
        $.ajax({
            url: '/kapcsolat/ajax-filter',
            data: {
               positionValue: $(this).val()
            },
            dataType: "json",
            success: function (response) {
                $.each($('#contact_garage option'), function (index, value) {
                    $(value).prop('disabled', false);
                    if(jQuery.inArray(value.value, response) == -1 && value.value != 'choose_garage') {
                        $(value).prop('disabled', true);
                    }
                })
            },
        });
    })
})(jQuery);

(function ($) {
    $('#contact_garage').on('change', function(){
        $.ajax({
            url: '/kapcsolat/ajax-filter',
            data: {
               garageValue: $(this).val()
            },
            dataType: "json",
            success: function (response) {
                $.each($('#contact_position option'), function (index, value) {
                    $(value).prop('disabled', false);
                    if(jQuery.inArray(value.value, response) == -1 && value.value != 'choose_position' && value.value != 'other') {
                        $(value).prop('disabled', true);
                    }
                })
            },
        });
    })
})(jQuery);