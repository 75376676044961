'use strict';

// add sentry
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
if (!/.*[\.]?localhost|.*[\.]?local\.hu/i.test(window.location.host)) {
    Sentry.init({
        dsn: 'https://2d2afe48e289450eb45a8ef7f31af4ac@o310262.ingest.sentry.io/5237483',
        environment: window.location.host,
        // debug: true,
        allowUrls: [
            new RegExp(window.location.host.replace(/www\.?/i, '').replace(/\./g, '\\.'), 'i'),
            new RegExp(window.location.host.replace(/\./g, '\\.'), 'i'),
        ],
        // @ts-ignore
        release: 'hrportal@' + packageVersion,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1
    });
}

import $ from 'jquery';
// @ts-ignore
window.$ = $;
// @ts-ignore
window.jQuery = $;
import 'foundation-sites/js/entries/foundation';
import 'magnific-popup';

import './app.scss';
import PlaceHolder from './js/placeholder';
import './js/hasScrollbar';
import './js/debounce';

import mapsytle from './js/mapstyle.json';
// @ts-ignore
window.mapstyle = mapsytle;
import './js/maps';

const FB_PIXEL_ID = '790829634264601';
const GA_TRACKING_ID = 'G-3SB25B6YPS';

const gtagLoader = document.createElement('script');
gtagLoader.src = 'https://www.googletagmanager.com/gtag/js?id=' + GA_TRACKING_ID;
gtagLoader.async = true;
document.querySelector('head').appendChild(gtagLoader);

// @ts-ignore
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }

// consent mode v2, a default az mindig denied
gtag('consent', 'default', {
    'functionality_storage': 'denied',
    'security_storage': 'denied',
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'ad_personalization': 'denied',
    'ad_user_data': 'denied'
});
if (localStorage.getItem('consentMode') !== null) {
    gtag('consent', 'update', JSON.parse(localStorage.getItem('consentMode')));
}

gtag('js', new Date());
gtag('config', GA_TRACKING_ID, { 'anonymize_ip': false });

//consent mode v2-höz van erre szükség, az elfogadott cookie-k alapján állítja be a taget
function setConsent(categories) {
    const consentMode = {
        'functionality_storage': categories.includes('necessary') ? 'granted' : 'denied',
        'security_storage': categories.includes('necessary') ? 'granted' : 'denied',
        'ad_storage': categories.includes('marketing') ? 'granted' : 'denied',
        'analytics_storage': categories.includes('analytics') ? 'granted' : 'denied',
        'ad_personalization': categories.includes('marketing') ? 'granted' : 'denied',
        'ad_user_data': categories.includes('marketing') ? 'granted' : 'denied',
    };
    gtag('consent', 'update', consentMode);
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
}

//analytics cookie-k elfogadása
function onAcceptAnalytics() {
    gtag('config', GA_TRACKING_ID);
}

//marketing cookie-k elfogadása
function onAcceptMarketing() {
    if (FB_PIXEL_ID.length) {
        // Facebook Pixel Code
        !function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        window.fbq('init', FB_PIXEL_ID);
        window.fbq('set', 'agent', 'tmgoogletagmanager', FB_PIXEL_ID);
        // pageview
        window.fbq('track', "PageView");
    }
}

//analytics cookie-k elutasítása
function onRejectAnalytics() {
    gtag('config', GA_TRACKING_ID, { 'store_gac': false });
    gtag('config', GA_TRACKING_ID, { 'anonymize_ip': false });
}

//marketing cookie-k elutasítása
function onRejectMarketing() {
}


import './js/contactform.js';

import 'vanilla-cookieconsent';

// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration

cc.run({
    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'middle center',     // bottom/middle/top + left/right/center
            transition: 'zoom',            // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },

    current_lang: 'hu',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false
    force_consent: true,
    auto_language: document,

    onAccept: function (cookie) {
        setConsent(cookie.categories);

        if (cookie.categories.includes("analytics")) {
            onAcceptAnalytics();
        }

        if (cookie.categories.includes("marketing")) {
            onAcceptMarketing();
        }

    },

    onChange: function (cookie, changed_preferences) {
        setConsent(cookie.categories);

        for (var i = 0; i < changed_preferences.length; i++) {
            if (cookie.categories.includes(changed_preferences[i])) {
                if (changed_preferences[i] == "analytics") {
                    onAcceptAnalytics();
                } else if (changed_preferences[i] == "marketing") {
                    onAcceptMarketing();
                }
            } else if (changed_preferences[i] == "analytics") {
                onRejectAnalytics();
            }
        }
    },

    languages: {
        'hu': {
            consent_modal: {
                title: 'Weboldalunk a megfelelő felhasználói élmények miatt sütiket használ',
                description: 'A böngésződ kis adatcsomagot tárol, ennek segítségével vihetsz végig például egy vásárlási folyamatot. Továbbá segít nekünk felismerni, hogy korábban jártál-e már az oldalunkon, pontosabb statisztikákat láthatunk és segíti a marketingünket is. <br><button type="button" data-cc="c-settings" class="cc-link">Beállítások</button>',
                primary_btn: {
                    text: 'Mindet elfogadom',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                }
            },
            settings_modal: {
                title: 'Cookie beállítások',
                save_settings_btn: 'Beállítások mentése',
                accept_all_btn: 'Mindet elfogadom',
                reject_all_btn: 'Elutasítom',
                close_btn_label: 'Close',
                blocks: [
                    {
                        title: 'Weboldalunk a megfelelő felhasználói élmények miatt sütiket használ',
                        description: 'A böngésződ kis adatcsomagot tárol, ennek segítségével vihetsz végig például egy vásárlási folyamatot. Továbbá segít nekünk felismerni, hogy korábban jártál-e már az oldalunkon, pontosabb statisztikákat láthatunk és segíti a marketingünket is.'
                    }, {
                        title: 'Funkcionális sütik',
                        description: 'Ezekre a sütikre azért van szükség, hogy a weboldal alap funkcióit biztosítani tudjuk számodra. Nélkülük egy egyszerűnek tűnő vásárlási folyamatot sem tudsz végig vinni, de a bejelentkezés sem működik. Segíti a kosár működését és a fizetési folyamat lebonyolítását, valamint a weboldal biztonságos területeihez való hozzáférést.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Statisztikai sütik',
                        description: 'A statisztikai sütik névtelen formában gyűjtenek adatokat és segítenek megérteni nekünk, hogy honnan érkeznek weboldal látogatóink.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: true,
                            readonly: false
                        }
                    }, {
                        title: 'Marketing sütik',
                        description: 'A marketing sütiket a látogatók weboldal tevékenységének nyomon követésére használjuk. Ennek segítségével tudunk személyre szabott hirdetéseket megjeleníteni számodra. Célja, hogy minél relevánsabb hirdetéseket tegyünk közzé, ez a Te érdekedet is szolgálja, hiszen olyan tartalommal találkozhatsz, ami valójában érdekel.',
                        toggle: {
                            value: 'marketing',
                            enabled: true,
                            readonly: false
                        }
                    }
                ]
            }
        }
    }
});

$(function () {
    $(document).foundation();

    $('.image-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.checkbox-list').each(function () {
        if ($(this).hasScrollBar()) {
            $(this).addClass('has-scrollbar');
        }

        $(this).scroll(function () {
            if ($(this).scrollTop()) {
                $(this).addClass('scrolled');
            } else {
                $(this).removeClass('scrolled');
            }

            if ($(this).scrollTop() + $(this).innerHeight() >= this.scrollHeight) {
                $(this).addClass('scrollend');
            } else {
                $(this).removeClass('scrollend');
            }
        });
    });

    let checkboxes = $('.checkbox-list input');
    const checkboxesChangeHandler = $.debounce(800, function () {
        $('#result-list').addClass('loading');
        $.ajax({
            url: `${window.location.origin}${window.location.pathname}?${decodeURIComponent(checkboxes.serialize())}`
        }).done(function (result) {
            $('.page').replaceWith(result);
            PlaceHolder();
            checkboxes = $('.checkbox-list input');
            checkboxes.change(checkboxesChangeHandler);
        });
    });

    checkboxes.change(checkboxesChangeHandler);

    var inputs = $('input[type="file"]');

    inputs.each(function () {
        var label = $(this).next('label').find('.file'),
            originalLabel = label.text();

        $(this).change(function () {
            var fileName = '';

            if (this.files && this.files.length > 1) {
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            } else {
                fileName = this.value.split('\\').pop();
            }

            if (fileName) {
                label.text(fileName);
                label.removeClass('empty');
            } else {
                label.text(originalLabel);
                label.addClass('empty');
            }
        });
    });
});
